











import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    commissionId: {
      type: String,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleError(e: Error) {
      this.$emit('item-forgive-error', e);
    },
    async forgive() {
      this.loading = true;

      await this.$store.dispatch('business/commission/item/Forgive', {
        BUSINESS_ID: this.businessId,
        COMMISSION_ID: this.commissionId,
        ITEM_ID: this.itemId,
      }).catch(this.handleError);
    },
  },
});
